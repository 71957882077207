<template v-if>
    <div>
      <ListAgents />
    </div>
  </template>
  
  
  
  <script>
  import ListAgents from './../components/ListAgents.vue';
  
  
    export default {
      name: "app",
      components: {
        ListAgents
      }
    }
  </script>