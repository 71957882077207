
<template>
    <v-app>
        <v-app-bar>
    
    <v-img src="@/assets/ax_logo.png" max-height="60" contain></v-img>

    <!-- Centered title or branding -->
    <v-toolbar-title class="text-h6 ml-4">
      User: Admin
    </v-toolbar-title>

    <!-- Spacer to push items to the right -->
    <v-spacer class="mr-19"></v-spacer>

    <!-- Right-aligned items 
    <v-btn icon @click="logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn> 
    -->
    <v-btn icon>
      <v-icon>mdi-logout</v-icon>
    </v-btn>

    
  </v-app-bar>

    <v-app-bar :height="3" color="white"></v-app-bar>
  
    <v-navigation-drawer image="@/assets/ax_logo.png">
    <v-list-item title="NAVIGATOR" subtitle="_______________________________"></v-list-item>
    <!--<v-list-item title="Menu" subtitle="_______________________________"></v-list-item>
    <v-divider></v-divider>
      <v-list-item to="/" link title="Transaction Form"></v-list-item>
      <v-list-item to="/campaign"  link title="Campaign Manager"></v-list-item>
      <v-list-item to="/customers"  link title="Customer Manager"></v-list-item>
      <v-list-item link title="Product Manager"></v-list-item>-->
      <v-list-item to="/secure/reports" link title="Reports"></v-list-item>
      <v-list-item to="/secure/agents" link title="Agents"></v-list-item>
     <!-- <v-list-item link title="Analytics Dashboard"></v-list-item>
      <v-list-item to="/chat" link title="Chat"></v-list-item>
      <v-list-item to="/jarvis" link title="Jarvis"></v-list-item>-->
  </v-navigation-drawer>
  <v-spacer :height="100"></v-spacer>
    <v-main>
     
      <router-view/>
    </v-main>
    <v-text-field tfbody="body text" />
    </v-app>
  </template>
  
  