<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar app dense>
      <!-- Logo on the far left -->
      <v-img src="@/assets/ax_logo.png" alt="Logo" contain max-height="60px" />

      <!-- Spacer to push the menu items to the right of the logo -->
      <v-spacer />

      <!-- Menu Items -->
      <v-btn text>Features</v-btn>
      <v-btn text>Pricing</v-btn>
      <v-btn text>Contact Us</v-btn>

      <!-- Spacer to push login/signup buttons to the far right -->
      <v-spacer />

      <!-- Login and Signup -->
      <v-btn text :to="{ path: '/login' }">Login</v-btn>
      <v-btn text color="primary">Signup Free</v-btn>
    </v-app-bar>

    <!-- Main Content -->
    <v-container class="fill-height">
      <v-row align="center">
        <!-- Left Column: Text Content -->
        <v-col cols="12" md="6">
          <h1 class="display-2 custom-heading">
            B2B hyperscaling for growing businesses
          </h1>
          <p class="subtitle-1">
            Targeted sales-agent and reseller sourcing that helps businesses close the relationship gap and boost conversion rates.
          </p>
        </v-col>

        <!-- Right Column: Image with animated circles -->
        <v-col cols="12" md="6" class="position-relative">
          <v-img src="@/assets/world_map.png" alt="Business Image" contain></v-img>

          <!-- Animated circles with random delays and durations -->
          <div
            v-for="n in 15"
            :key="n"
            class="animated-circle red-circle"
            :style="getCircleStyle()"
          ></div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Footer -->
    <v-footer app>
      <v-container class="d-flex flex-column align-center">
        <!-- Copyright -->
        <p class="text-center mb-0">Copyright 2024 AgentXplore</p>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'HomeLayout',
  mounted() {
    // Function to randomize circle positions
    this.randomizeCircles();
  },
  methods: {
    randomizeCircles() {
      const circles = document.querySelectorAll('.animated-circle');

      circles.forEach(circle => {
        const randomX = Math.random() * 100; // Random position for X-axis
        const randomY = Math.random() * 100; // Random position for Y-axis
        circle.style.left = `${randomX}%`;
        circle.style.top = `${randomY}%`;
      });

      setTimeout(this.randomizeCircles, 3000); // Reposition circles every 3 seconds
    },
    getCircleStyle() {
      const randomDelay = Math.random() * 5 + "s"; // Random delay between 0 and 5 seconds
      const randomDuration = (Math.random() * 2 + 2) + "s"; // Random duration between 2 and 4 seconds
      return {
        animationDelay: randomDelay,
        animationDuration: randomDuration,
      };
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

/* Custom styles for this component */
.custom-heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800; /* Extra Bold */
  font-size: 50px;  /* Font size */
  color: #555555;   /* Dark Grey */
}

.position-relative {
  position: relative;
}

/* Styles for the animated circles */
.animated-circle {
  position: absolute;
  width: 5px;  /* Half the original size */
  height: 5px; /* Half the original size */
  border-radius: 50%;
  background-color: red;
  animation: fadeInOut ease-in-out infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>

