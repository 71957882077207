<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Add any global styles here */
</style>