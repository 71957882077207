<template>
    <v-card class="mx-auto" tile>
      <v-card-title>Registered Agents</v-card-title>
  
      <v-data-table
        :headers="headers"
        :items="list"
        disable-pagination
        :hide-default-footer="true"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.agent_name }}</td>
            <td>{{ item.agent_surname }}</td>
            <td>{{ item.agent_email }}</td>
            <td>{{ item.agent_mobile }}</td>
            <td>{{ item.agent_country }}</td>
            <td>{{ item.agent_city }}</td>
            <td>
              <v-icon small class="mr-2" @click="editAgent(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteAgent(item.id)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  axios.defaults.baseURL = 'https://www.agentxplore.com';
  import { ref } from 'vue'; // Ensure you import ref if using Vue 3 Composition API
  
  export default {
    name: 'ListAgents',
    setup() {
      const list = ref([]); // Use ref for reactive data
  
      const headers = [
        { text: 'Name', value: 'agent_name' },
        { text: 'Surname', value: 'agent_surname' },
        { text: 'Email', value: 'agent_email' },
        { text: 'Mobile', value: 'agent_mobile' },
        { text: 'Country', value: 'agent_country' },
        { text: 'City', value: 'agent_city' },
        { text: 'Actions', sortable: false } // Add a header for actions column
      ];
  
      const fetchAgents = () => {
        axios.get('https://www.agentxplore.com/api/agents')
          .then((resp) => {
            list.value = resp.data;
            console.warn(resp.data);
          })
          .catch((error) => {
            console.error('Error fetching agents:', error);
          });
      };
  
      const editAgent = (agent_name) => {
        // Handle edit logic here (e.g., navigate to edit page)
        console.log('Edit agent:', agent_name);
      };
  
      const deleteAgent = (agent_name) => {
        // Handle delete logic here (e.g., confirm dialog and delete request)
        console.log('Delete agent:', agent_name);
      };
  
      // Ensure to return the data and methods
      return {
        list,
        headers,
        fetchAgents,
        editAgent,
        deleteAgent
      };
    },
    mounted() {
      this.fetchAgents();
    }
  };
  </script>
  
  <style scoped>
  /* Optionally, add scoped styles for this component */
  </style>
  
