import { createRouter, createWebHistory } from 'vue-router';
import HomeLayout from '@/layouts/HomeLayout.vue';
import MainLayout from  '@/layouts/MainLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import CustomerView from '../views/CustomerView.vue';
import ReportsView from '../views/ReportsView.vue';
import AgentsView from '../views/AgentsView.vue';
import LoginView from '../views/LoginView.vue'; // Import the Login component
import { isAuthenticated } from '@/auth';

const routes = [
  {
    path: '/secure',
    component: MainLayout,
    children: [
      {
        path: 'customers', // Adjusted to be relative
        name: 'customers',
        component: CustomerView,
        meta: { requiresAuth: true }
      },
      {
        path: 'agents', // Adjusted to be relative
        name: 'agents',
        component: AgentsView,
        meta: { requiresAuth: true }
      },
      {
        path: 'reports', // Adjusted to be relative
        name: 'reports',
        component: ReportsView,
        meta: { requiresAuth: true }
      },
      {
        path: 'about', // Adjusted to be relative
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login', // Nested route under /auth
        name: 'Login',
        component: LoginView,
      }
      // Add more auth routes here (e.g., signup)
    ]
  },
  {
    path: '/login', // Direct login route
    component: LoginView,
  },
  {
    path: '/',
    component: HomeLayout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
